import { useEffect, useState } from "react";
import ProgrammingPills from "../components/ProgrammingPills";
import WorkExperience2 from "./WorkExperience2";

const Me = () => {
  const [useHyphensInWorkExperience, setUseHyphensInWorkExperience] =
    useState<boolean>(false);

  useEffect(() => {
    const toggleHyphensInWorkExperience = (e: KeyboardEvent) => {
      if (e.key === "-") {
        setUseHyphensInWorkExperience(!useHyphensInWorkExperience);
      }
    };

    window.addEventListener("keydown", toggleHyphensInWorkExperience);

    return () =>
      window.removeEventListener("keydown", toggleHyphensInWorkExperience);
  });

  return (
    <div className="container-md me">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8">
          <div className="bg-white border pt-3 px-3 pb-0">
            <p>Hi, I&apos;m Neuton.</p>
            <p>
              Here&apos;s a link to my{" "}
              <a
                href="https://drive.google.com/file/d/14Spt-Eg3j2XhZheM5QHeHwXG4Uta5h0H/view?usp=sharing"
                target="_blank"
                rel="noreferrer"
              >
                resume
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      <h2 className="page-heading text-center my-3">Education</h2>
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8">
          <div className="bg-white border p-3">
            <strong>University of Southern California</strong>
            <em className="text-secondary d-block d-md-inline">
              {" "}
              - Viterbi School of Engineering
            </em>
            <div className="row my-3">
              <div className="col-6 col-md-8">
                <div className="ps-md-5">
                  Bachelor of Science, Computer Science
                </div>
              </div>
              <div className="col-6 col-md-4 text-center">
                <em>
                  Aug 2017 —<span className="d-block d-sm-inline"></span> May
                  2021
                </em>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6 col-md-8">
                <div className="ps-md-5">
                  Master of Science, Computer Science
                </div>
              </div>
              <div className="col-6 col-md-4 text-center">
                <em>
                  Aug 2021 —<span className="d-block d-sm-inline"></span> May
                  2022
                </em>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8 col-xl-4">
          <h2 className="page-heading text-center my-3">Programming</h2>
          <div className="bg-white border pt-3 pb-2 px-3 text-center">
            <ProgrammingPills
              languages={[
                "Python",
                "C++",
                "Java",
                "Node.js",
                "React",
                "HTML",
                "CSS",
                "JavaScript",
                "jQuery",
                "PHP",
                "SQL",
                "Linux CLI",
                "Shell",
                "Docker",
                "Kubernetes",
                "AWS",
                "GCP",
                "Machine Learning",
              ]}
            />
          </div>
        </div>
        <div className="col-12 col-lg-8 col-xl-4">
          <h2 className="page-heading text-center my-3">Certifications</h2>
          <div className="bg-white border text-center px-3 pt-3 pb-2">
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 green white-text">
              AWS Certified Solutions Architect — <em>Associate</em>
            </div>
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 deep-purple darken-4 white-text">
              DeepLearning.AI — <em>Deep Learning Specialization</em>
            </div>
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 red darken-4 white-text">
              Japanese-Language Proficiency Test (JLPT) — <em>N5</em>
            </div>
          </div>
        </div>
      </div>
      <h2 className="page-heading text-center my-3">Involvement</h2>
      <div className="row justify-content-center text-center">
        <div className="col-12 col-lg-8">
          <div className="bg-white border px-3 pt-3 pb-2">
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 pink lighten-2 white-text">
              🏳️‍🌈 SF Pride Parade 2019, 2021 — <em>Event Volunteer</em>
            </div>
            <br />
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 orange darken-2 white-text">
              🌏 Etsy Asian Resource Group ERG 2024 — <em>Co-Lead</em>
            </div>
            <hr className="mt-2 p-0" />
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 deep-purple white-text">
              🔭 USC Scope
            </div>
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 red accent-4 white-text">
              🤖 USC Makers
            </div>
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 blue lighten-4 black-text">
              📝 USC SplashSC
            </div>
            <span className="d-block"></span>
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 cyan lighten-1 black-text">
              🌏 USC International Students Association
            </div>
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 grey lighten-2 red-text text-darken-2">
              🇸🇬 USC Singapore Students Association
            </div>
            <span className="d-block"></span>
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 red darken-4 white-text">
              🌲 TreeHacks 2018 — <em>Hacker</em>
            </div>
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 orange white-text">
              🐴 TrojanHacks 2018 — <em>Hacker</em>
            </div>
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 yellow darken-2 grey-text text-darken-3">
              🏖️ Santa Barbara Hacks 2019 — <em>Hacker</em>
            </div>
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 blue white-text">
              🌴 LA Hacks 2019 — <em>Hacker</em>
            </div>
            <div className="badge rounded-pill programming-language-pill p-2 me-1 mb-2 deep-purple darken-4 white-text">
              🦉 AthenaHacks 2019, 2021 — <em>Mentor</em>
            </div>
          </div>
        </div>
      </div>
      <h2 className="page-heading text-center my-3">Work Experience</h2>
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8">
          <div className="bg-white border px-3 pt-3 pb-0">
            <WorkExperience2
              useHyphensInWorkExperience={useHyphensInWorkExperience}
              company="Etsy, Inc."
              roles={[
                {
                  name: "Risk — Software Engineer II",
                  location: "New York City, NY",
                  startDate: "Aug 2024",
                  endDate: "Ongoing",
                  responsibilities: [
                    "Increased availability of account and session metadata for all of Etsy's rules engines, used in both the Risk and Content Moderation spaces, to enable more data-driven rule evaluations through the Symphony Expression Language",
                    "Developed upon the user experience in rules engines for the detection of bad actors, collusion and bot activity by tightening integration with the engine's CodeMirror rule editor and improving code hinting functionality",
                    "Improved observability for rules engines for the automatic flagging of policy infringing shops and listings, including detection and actioning of OFAC infringing listings, by implementing a dashboard for rule writers to view individual rule latency and precision",
                    "Increased efficiency of the manual human evaluation of low precision rules by introducing and leveraging LLMs in rule engines to reduce the flagging of false positives",
                  ],
                },
                {
                  name: "Payments — Software Engineer I / II",
                  location: "New York City, NY",
                  startDate: "Aug 2021",
                  endDate: "Jan 2024",
                  responsibilities: [
                    "Developed and A/B tested user-facing web features within the Etsy Payments and Checkout space to optimize for business metrics, with a combined impact of $12.3M in Gross Merchandise Sales, a +0.53% lift to sign ins, a +1.37% lift in checkout starts, a +0.70% lift to 30 day reorder rate, and a +1.01% lift in Conversion Rate",
                    "Led feature development for several experiments on the Listing, Cart and Checkout pages, including Buy It Now button user experience changes, login interstitial changes to incentivize sign-ups, with React, Redux and PHP",
                  ],
                },
                {
                  name: "Payments — Software Engineering Intern",
                  location: "Los Angeles, CA",
                  startDate: "Jun 2021",
                  endDate: "Aug 2021",
                  responsibilities: [
                    "Expanded Etsy Payments Klarna availability in the United Kingdom, Spain and Italy, and added observability into Klarna usage with StatsD, Graphite and Grafana",
                  ],
                },
              ]}
            />
            <WorkExperience2
              useHyphensInWorkExperience={useHyphensInWorkExperience}
              company="Stealth Startup"
              roles={[
                {
                  name: "Software Engineer",
                  location: "New York City, NY",
                  startDate: "Jan 2024",
                  endDate: "May 2024",
                  responsibilities: [
                    "Built web platform to interface with an AOSP powered hardware device with TypeScript, Next.js, Tailwind, MongoDB and web sockets on Google Cloud Platform",
                    "Worked with various LLM APIs for prompt powered web automation, including Perplexity, OpenAI, and Deepgram",
                  ],
                },
              ]}
            />
            <WorkExperience2
              useHyphensInWorkExperience={useHyphensInWorkExperience}
              company="Rakuten Viki"
              roles={[
                {
                  name: "DevOps Intern",
                  location: "Singapore",
                  startDate: "May 2020",
                  endDate: "Aug 2020",
                  responsibilities: [
                    "Developed a Go Slack bot for the Spinnaker continuous delivery platform",
                    "Prepared and deployed the Slack bot as a micro service with Helm on Google Kubernetes Engine (GKE)",
                    "Improved Spinnaker pipeline templates by migrating to Jsonnet based templates for extended customization",
                  ],
                },
              ]}
            />
            <WorkExperience2
              useHyphensInWorkExperience={useHyphensInWorkExperience}
              company="The University of Southern California"
              roles={[
                {
                  name: "Course Producer",
                  location: "Los Angeles, CA",
                  startDate: "Aug 2019",
                  endDate: "May 2021",
                  responsibilities: [
                    "Instructed as a Course Producer for CSCI 102: Fundamentals of Computation",
                    "Led lab sections for beginners on the core fundamentals of computer science and programming in C++",
                  ],
                },
                {
                  name: "Teaching Assistant",
                  location: "Los Angeles, CA",
                  startDate: "Jan 2019",
                  endDate: "May 2021",
                  responsibilities: [
                    "Instructed as a Teaching Assistant for ITP 303: Full-Stack Web Development",
                    "Introduced students to web technologies including HTML, CSS, JavaScript, jQuery, PHP, AJAX and MySQL",
                    "Advised students with their assignments and final personal projects for 4 semesters of ~80 students each",
                  ],
                },
              ]}
            />
            <WorkExperience2
              useHyphensInWorkExperience={useHyphensInWorkExperience}
              company="Oracle Corporation"
              roles={[
                {
                  name: "Software Engineering Intern",
                  location: "Redwood City, CA",
                  startDate: "May 2019",
                  endDate: "Aug 2019",
                  responsibilities: [
                    "Developed a user behavior and click analysis utility to identify optimization points for Oracle Fusion Financials",
                    "Deployed containerized Python and Shell scripts with Docker on Red Hat Enterprise Linux (Oracle Linux)",
                    "Prototyped web-based visualization interface with SQL (Oracle Database) on Oracle APEX",
                  ],
                },
              ]}
            />
            <WorkExperience2
              useHyphensInWorkExperience={useHyphensInWorkExperience}
              company="Business Buddy"
              roles={[
                {
                  name: "Web Development Intern",
                  location: "Singapore",
                  startDate: "May 2017",
                  endDate: "Jul 2017",
                  responsibilities: [
                    "Conducted quality assurance on Laravel based PHP web applications for company clients",
                    "Tested application flow through unit testing and documented bugs on GitHub",
                  ],
                },
              ]}
            />
            <WorkExperience2
              useHyphensInWorkExperience={useHyphensInWorkExperience}
              horizontalRule={false}
              company="Scout Real Estate"
              roles={[
                {
                  name: "Web Developer",
                  location: "Beijing, China",
                  startDate: "Dec 2012",
                  endDate: "Jun 2013",
                  responsibilities: [
                    "Expanded company website's SEO through adjustments to its internal search feature to increase website traffic",
                    "Improved user interface and user experience through integration of the Google Maps API to display properties",
                  ],
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Me;
