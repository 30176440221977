import "./WorkExperience.scss";

export interface Role {
  name: string;
  location: string;
  startDate: string;
  endDate: string;
  responsibilities: string[];
}

const WorkExperience2 = ({
  useHyphensInWorkExperience,
  company,
  roles,
  horizontalRule = true,
}: {
  useHyphensInWorkExperience: boolean;
  company: string;
  roles: Role[];
  horizontalRule?: boolean;
}) => {
  return (
    <>
      <div className="work-experience">
        <div className="text-center text-lg-start mb-2 mb-sm-1">
          <strong className="fs-5">{company}</strong>
        </div>
        {roles.map((role, roleIndex) => (
          <div key={roleIndex} className="mb-2">
            <div className="row mb-2 text-center text-sm-start">
              <div className="col-12 col-sm-8 align-self-center">
                <div className="text-center text-sm-start ms-sm-3 mb-1 mb-sm-0">
                  <strong>{role.name}</strong>
                </div>
              </div>
              <div className="col-12 col-sm-4 text-sm-end">
                <div className="me-sm-3">
                  <small className="ms-sm-3">
                    <em>
                      {role.startDate} — {role.endDate}
                    </em>
                  </small>
                </div>
                <div className="me-sm-3">
                  <small>{role.location}</small>
                </div>
              </div>
            </div>
            <div className="experience">
              <ul>
                {role.responsibilities.map(
                  (responsibility, responsibilityIndex) => (
                    <li key={responsibilityIndex} className="mb-2 mb-sm-1">
                      {useHyphensInWorkExperience ? "- " : ""}
                      {responsibility}
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        ))}
      </div>
      {horizontalRule && <hr />}
    </>
  );
};

export default WorkExperience2;
